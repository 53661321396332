<template>
<div class="result-content">
  <div class="result-box">
    <div class="result-title">{{  $t('thank-you') }}</div>
    <div class="result-body">   {{  $t('your-result') }}
        <span class="result-container"> {{ text(1) }}</span>
    </div>
    <div class="result-footer">{{ text(2) }}</div>
  </div>
  <div class="description">
    <div class="description-header">
        <p class="desc-header-text">{{ text(3) }}</p>
        🧑‍💻
    </div>
    <div class="description-body">
        🤑
        <p class="desc-body-text">{{ text(4) }}</p>
    </div>
    <div class="description-footer"> 
        {{ $t('we-picked') }}
        <span class="footer-color">{{ $t('offer') }}</span>
        {{ $t('get-up') }}
        <span class="footer-color">{{ $t('gain') }} </span>
        {{ $t('goal') }}
        <span class="footer-answer"><span>{{ $t(result[4].text) }}</span></span>
    </div>
  </div>
  <div class="offer-btn">
    <div class="offer-button" role="button" @click="$redirect()">
        <span class="offer-buttont-text">{{ $t('get-offer') }}</span>
    </div>
  </div>
</div>
</template>

<script>
export default {
    name: "Result",
    props: [ "result" ],
    mounted() {
        this.$setIdleMax(10000);
    },
    methods: {
        text(id) {
            let variant = this.result[3].id + 1;
            return this.$t('result' + variant + '-' + id);
        }
    }
}
</script>

<style>
.result-box {
    background: #001f66;
    border: 1px solid hsla(0,0%,100%,.1);
    border-radius: 25px;
    margin: 0 0 5px;
    padding: 24px 0;
    color: #fff;
    margin: 0 0 15px;
}
.result-title {
    font-size: 40px;
    line-height: 47px;
    margin-bottom: 20px;
    font-weight: 700;
    letter-spacing: .08em;
    text-transform: uppercase;
}
.result-body {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 20px;
    color: #ecfd65;
    font-weight: 700;
    letter-spacing: .09em;
}
.result-container {
    display: block;
}
.result-footer {
    font-size: 20px;
    line-height: 23px;
}
.description {
    padding: 24px 24px 0;
    background: #001f66;
    border: 1px solid hsla(0,0%,100%,.1);
    border-bottom: none;
    border-radius: 16px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    color: #fff;
}
.description-header {
    margin-bottom: 15px;
    align-items: center;
    display: flex;
    font-size: 32px;
    justify-content: space-around;
}
.desc-header-text {
    font-size: 20px;
    line-height: 28px;
    max-width: 385px;
    width: 100%;
}
.description-body {
    margin-bottom: 25px;
    align-items: center;
    display: flex;
    font-size: 32px;
    justify-content: space-around;
}
.desc-body-text {
    font-size: 20px;
    line-height: 28px;
    max-width: 385px;
    width: 100%;
}
.description-footer {
    font-size: 16px;
    letter-spacing: .09em;
    line-height: 22px;
    font-weight: 400;
    text-align: start;
}
.footer-color {
    color: #ecfd65;
}
.footer-answer {
    text-transform: uppercase;
}
.offer-btn {
    padding: 26px 0 20px;
    background: #001f66;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    margin-bottom: 0;
    flex-direction: row;
    justify-content: space-evenly;
    align-content: center;
    align-items: center;
    display: flex;
    flex-flow: row wrap;
}
.offer-button {
    margin: 0;
    background: linear-gradient(94.89deg,#5bf2fb,#5f90fe);
    border: 1px solid #ecfd65;
    box-shadow: 1px 1px 10px hsla(0,0%,100%,.25),-1px -1px 10px hsla(0,0%,100%,.25);
    min-width: 188px;
    max-width: 280px;
    padding: 0 10px;
    align-items: center;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    min-height: 60px;
    cursor: pointer;
}
.offer-buttont-text {
    color: #000;
    display: table;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: .09em;
    line-height: 21px;
    text-align: center;
}
</style>