var idle_default = 60000;
var wait = null;
var callback = null;

export function setIdleMax(max_time) {
    if (max_time == 0) {
        clearTimeout(wait);
        wait = null;
        return;
    }
    idle_default = max_time;
    startIdleCheck(callback);
}

export function startIdleCheck(cbk) {
    if (wait) {
        clearTimeout(wait);
    }
    callback = cbk;
    wait = setTimeout(cbk, idle_default);
    document.onmousedown = document.onmouseup = document.onkeydown = document.onkeyup = ()=>{
        clearTimeout(wait);
        wait = setTimeout(cbk, idle_default);
    }
}