<template>
    <div class="survey-calculation">
        <div class="calculation-body">
            <div class="calculation-title"></div>
            <div class="calculation-text">{{ $t(text) }}</div>
            <div class="calculation-progress" :style="{'animation-duration':total + 'ms'}"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Calculation",
    data() {
        return {
            status: 0,
            idle: 'loading1',
            statuses: ['loading2', 'loading3'],
            text: '',
            duration: 2000,
            total: 0
        }
    },
    mounted() {
        this.total = ( this.statuses.length * 2 + 1 ) * this.duration;
        this.tick();
    },
    methods: {
        tick() {
            if (this.status % 2 == 0) {
                this.text = this.idle;
            } else {
                let it = Math.floor(this.status / 2);
                if (it == this.statuses.length) {
                    this.$emit('complete');
                    return;
                }
                this.text = this.statuses[it];
            }
            this.status++;
            setTimeout(()=>{this.tick()}, this.duration);
        }
    }
}
</script>

<style>
.survey-calculation {
    background-color: #5b5959;
    border-radius: 25px;
    color: #fff;
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    left: 0;
    padding: 20px;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 10;
}
.calculation-body {
    width: 100%;
    box-sizing: border-box;
}
.calculation-title {
    font-weight: 700;
    margin-bottom: 20px;
}
.calculation-text {
    margin-bottom: 20px;
}
.calculation-progress {
    display: block;
    height: 4px;
    margin: 20px auto;
    overflow: hidden;
    width: 100%;
}
.calculation-progress::before {
    -webkit-animation-duration: inherit;
    animation-duration: inherit;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-name: survey-progress;
    animation-name: survey-progress;
    -webkit-animation-timing-function: ease;
    animation-timing-function: ease;
    background: #29b765;
    content: "";
    display: block;
    height: 100%;
    width: 100%;
}
@keyframes survey-progress {
    0% {
        -webkit-transform:translate(-100%);
        transform:translate(-100%)
    }
    to {
        -webkit-transform:translate(0);
        transform:translate(0)
    }
}
</style>