<template>
<div class="result-content">
  <div class="result-box">
    <div class="result-title">{{  $t('sorry') }}</div>
    <div class="result-footer">{{ $t('age-restrict') }}</div>
  </div>
</div>
</template>

<script>
export default {

}
</script>

<style>
.result-box {
    background: #001f66;
    border: 1px solid hsla(0,0%,100%,.1);
    border-radius: 25px;
    margin: 0 0 5px;
    padding: 24px 0;
    color: #fff;
    margin: 0 0 15px;
}
.result-title {
    font-size: 40px;
    line-height: 47px;
    margin-bottom: 20px;
    font-weight: 700;
    letter-spacing: .08em;
    text-transform: uppercase;
}
.result-footer {
    margin: auto;
    width: 80%;
}
</style>