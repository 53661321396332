<template>
    <div class="answer-box">
        <ProgressBar :count="current" :total="answers.length" :min="1" :text="'progress-text'"/>
        <div class="survey-question-text">
            {{ $t(answers[current].text) }}
        </div>
        <div class="survey-options">
            <div class="survey-button"  v-for="(item, index) in answers[current].variants" :key="index" v-on:click="answer(index)">
                <picture><img class="survey-option-image" :src="item.icon"></picture>
                <span class="survey-option-text">{{ $t(item.text) }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import ProgressBar from './basic/ProgressBar.vue';


export default {
    name: "AnswerBox",
    components: {
    ProgressBar
},
    methods: {
        answer(index) {
            if (this.current < this.answers.length - 1) {
                if (this.answers[this.current].variants[index].restricted) {
                    this.$emit('restricted');
                    return;
                }
                this.path.push({ id: index, text: this.answers[this.current].variants[index].text });
                this.current++;
            } else {
                this.$emit('complete', this.path);
            }
        }
    },
    data() {
        return {
            path: [],
            current: 0,
            answers: [
                {
                    text: 'answer1',
                    variants: [
                        {
                            text: 'answer1-1',
                            icon: require("@/assets/man.webp")
                        },
                        {
                            text: 'answer1-2',
                            icon: require("@/assets/woman.webp")
                        }
                    ]
                },
                {
                    text: 'answer2',
                    variants: [
                        {
                            text: 'answer2-1',
                            icon: require("@/assets/yes.webp")
                        },
                        {
                            text: 'answer2-2',
                            icon: require('@/assets/close.webp'),
                            restricted: true
                        }
                    ]
                },
                {
                    text: 'answer3',
                    variants: [
                        {
                            text: 'answer3-1',
                            icon: require("@/assets/work.webp")
                        },
                        {
                            text: 'answer3-2',
                            icon: require('@/assets/self.webp')
                        },
                        {
                            text: 'answer3-3',
                            icon: require("@/assets/look.webp")
                        },
                        {
                            text: 'answer3-4',
                            icon: require('@/assets/other-work.webp')
                        }
                    ]
                },
                {
                    text: 'answer4',
                    variants: [
                        {
                            text: 'answer4-1',
                            icon: require("@/assets/bag1.webp")
                        },
                        {
                            text: 'answer4-2',
                            icon: require('@/assets/bag2.svg')
                        },
                        {
                            text: 'answer4-3',
                            icon: require("@/assets/bag3.webp")
                        },
                        {
                            text: 'answer4-4',
                            icon: require('@/assets/bag4.webp')
                        }
                    ]
                },
                {
                    text: 'answer5',
                    variants: [
                        {
                            text: 'answer5-1',
                            icon: require("@/assets/family-vacation.webp")
                        },
                        {
                            text: 'answer5-2',
                            icon: require('@/assets/venicle.webp')
                        },
                        {
                            text: 'answer5-3',
                            icon: require("@/assets/home.webp")
                        },
                        {
                            text: 'answer5-4',
                            icon: require('@/assets/start.webp')
                        }
                    ]
                },
                {
                    text: 'answer6',
                    variants: [
                        {
                            text: 'answer6-1',
                            icon: require("@/assets/smile-thinck.webp")
                        },
                        {
                            text: 'answer6-2',
                            icon: require('@/assets/smile.webp')
                        },
                        {
                            text: 'answer6-3',
                            icon: require("@/assets/smile-glasses.webp")
                        },
                        {
                            text: 'answer6-4',
                            icon: require('@/assets/smile-black.webp')
                        }
                    ]
                }
            ]
        }
    }
}
</script>

<style>
.survey-options {
    align-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin: 0 auto 20px;
    flex-flow: row wrap;
}
.survey-button {
    align-items: center;
    background: #1b2537;
    border: 1px solid #395da5;
    border-radius: 16px;
    box-shadow: 0 -2px 4px rgba(0,0,0,.25);
    display: flex;
    justify-content: center;
    max-width: 165px;
    min-height: 87px;
    cursor: pointer;
    flex: 0 0 40%;
    margin: 1rem;
    padding: 1rem;
    text-align: center;
    transition: .4s;
}
.survey-button:hover {
    background-color: #516280;
}
.survey-option-image {
    margin-left: 10px;
    width: 27px;
    height: auto;
    max-width: 100%;
}
.survey-option-text {
    align-items: center;
    color: #fff;
    display: flex;
    font-size: 10px;
    font-weight: 700;
    letter-spacing: .05em;
    line-height: 14px;
    order: -1;
    padding-right: 1px;
    text-align: start;
    text-transform: uppercase;
}
.answer-box {
    background: #001f66;
    border: 1px solid hsla(0,0%,100%,.1);
    border-radius: 16px;
    color: #fff;
    padding-top: 53px;
}
.survey-question-text {
    color: #ecfd65;
    font-size: 15px;
    letter-spacing: .05em;
    line-height: 22px;
    margin: 0 auto 10px;
    max-width: 95%;
    text-transform: uppercase;
}
@media screen and (min-width: 768px) {
    .finance-money .survey__question-text {
        font-size: 20px;
        line-height: 33px;
        max-width: 77%;
    }
}
@media screen and (min-width: 380px) {
    .survey-option-text {
        font-size: 11px;
    }
}
</style>