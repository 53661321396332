<template>
  <div class="rating">
    <div class="rating-title">{{ $t('rating-title') }}</div>
    <div class="rating-container">
      <div class="rating-number">
        <p class="rating-number-big">4.9</p>
        <p class="rating-number-text">{{ $t('rating-of5') }}</p>
      </div>
      <rating-stars/>
    </div>
  </div>
</template>

<script>
import RatingStars from './basic/RatingStars.vue'
export default {
  components: { RatingStars },
    name: "Rating"
}
</script>

<style>
.rating {
    margin-top: 16px;
    color: #fff;
}
.rating-title {
    font-size: 26px;
    line-height: 30px;
    text-transform: uppercase;
}
.rating-container {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.rating-number-big {
  color: #ecfd65;
  font-size: 48px;
  font-weight: 400;
  letter-spacing: .05em;
  line-height: 56px;
  text-align: center;
}
.rating-number-text {
  color: #a3a3a3;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: .05em;
  line-height: 14px;
  text-align: center;
}
@media screen and (min-width: 768px) {
  .rating-container {
    margin-top: 25px;
  }
  .rating-number-big {
    font-size: 60px;
    line-height: 70px;
  }
}
</style>