<template>
    <div class="survey">
        <div class="survey-content">
            <AnswerBox v-if="status == 0" @complete="getResult" @restricted="restricted" />
            <Calculation v-if="status == 1" @complete="status++" />
            <Result v-if="status == 2" :result="result"/>
            <Unavailable v-if="status == 3" />
        </div>
        <Rating/>
        <div class="rating-caption" v-if="status != 2">
            <div class="caption-text">{{ $t('rating-rate') }}</div>
        </div>
    </div>
</template>

<script>
import AnswerBox from './AnswerBox.vue';
import Calculation from './basic/Calculation.vue';
import Unavailable from './basic/Unavailable.vue';
import Rating from './Rating.vue';
import Result from './Result.vue';

export default {
    name: "Squiz",
    components: {
        AnswerBox,
        Calculation,
        Result,
        Rating,
        Unavailable,

    },
    data() {
        return {
            status: 0,
            result: []
        }
    },
    methods: {
        getResult(result) {
            this.result = result;
            this.status++;
        },
        restricted() {
            this.status = 3;
            //disable idle redirect
            this.$setIdleMax(0);
        }
    }
}
</script>

<style>
.survey {
    border: none;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 450px;
    padding: 0 5px;
    color: #222;
    font-size: 2rem;
    min-height: 200px;
    opacity: 1;
    padding: 2rem;
    transition: .4s;
    position: relative;
}
.caption-text {
    color: #5f90fe;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: .12em;
    line-height: 17px;
    margin: 20px auto 0;
    max-width: 316px;
    text-align: center;
    text-transform: uppercase;
}
@media screen and (min-width: 768px) {
    .survey {
        max-width: 500px;
        padding: 0;
    }
}

</style>