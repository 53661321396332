<template>
  <div id="app">
    <Languages/>
    <Rain/>
    <header class="header">
      <Logo/>
      <Header/>
    </header>
    <main class="main content">
      <Squiz/>
      <Reviews/>
    </main>
    <footer style="height: 40px;" />
  </div>
</template>

<script>
import Rain from './components/basic/Rain.vue';
import Languages from './components/basic/Languages.vue';
import Header from './components/basic/Header.vue';
import Squiz from './components/Squiz.vue';
import Logo from './components/basic/Logo.vue';
import Reviews from './components/Reviews.vue';

export default {
  name: 'App',
  components: {
    Languages,
    Rain,
    Header,
    Squiz,
    Logo,
    Reviews
  },
  mounted() {
    this.$startIdleCheck(this.$redirect)
  }
}
</script>

<style>
html {
  font-family: sans-serif;
  text-size-adjust: 100%;
}
body,html {
    color: #fff;
    font-family: -apple-system,Roboto,Helvetica Neue,Trebuchet MS,sans-serif;
    font-size: .6rem;
    height: 100%;
    margin: 0;
    padding: 0;
    text-align: center;
    background-color: #222;
}
a,img {
    border: 0;
    outline: 0
}
a {
    color: #fff;
    text-decoration: none;
}
h1, h2, p {
    font-size: 1rem;
    font-weight: 400;
    margin: 0;
    padding: 0;
}
.main {
    flex: 1 0 auto;
    margin-bottom: 7rem;
    position: relative;
    z-index: 3;
    width: auto;
}
.header {
    flex: 0 0 auto;
    position: relative;
    z-index: 4;
}
@media screen and (min-width: 768px) {
  .header {
      margin-bottom: 40px;
  }
}
@media screen and (max-width: 1300px) {
  body, html {
      font-size: .5rem;
  }
}
@media (max-width: 1000px) {
	.header {
		padding:0 20px
	}
}
</style>
