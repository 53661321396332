<template>
  <div :class="main_class">
    <div :class="line_class" :style="{width: line_width}"></div>
    <div :class="text_class" v-if="progress_text">{{ progress_text }}</div>
  </div>
</template>

<script>
export default {
    name: "ProgressBar",
    props: {
        count :{ type: Number, default: 1 },
        total :{ type: Number, default: 100 },
        text  :{ type: String, default: null },
        min   :{ type: Number, default: 0 },

        main_class : { type: String, default: "progress-bar" },
        line_class : { type: String, default: "progress-line" },
        text_class : { type: String, default: "progress-text" }
    },
    //props: ['count', 'maxCount'],
    computed: {
        line_width()    { return ( ( Math.max(this.min, this.count) ) / this.total * 100 ).toFixed(2) + "%"; },
        progress_text() { return this.text ? this.$t(this.text, { count: Math.max(this.min, this.count), total: this.total}) : false; }
    }
}
</script>

<style>
.progress-bar {
    background: hsla(0,0%,100%,.6);
    border-radius: 10px;
    left: 0;
    margin: 0 auto;
    order: -1;
    position: absolute;
    right: 0;
    top: 20px;
    width: 62%;
}
.progress-line {
    background: linear-gradient(90deg,#5bf2fb,#5f90fe);
    border-radius: 10px;
    height: 20px;
}
.progress-text {
    font-size: 14px;
    left: 0;
    line-height: 13px;
    position: absolute;
    right: 0;
    top: 18%;
    color: #222;
}
</style>