<template>
    <div class="content">
        <h1 class="title">{{ $t('title') }}</h1>
        <h2 class="subtitle">{{ $t('subtitle') }}</h2>
    </div>
</template>

<script>
export default {
    name: "Header"
}
</script>

<style>
.content {
    padding-top: 12px;
    width: auto;
    z-index: 4;
}
.title {
    font-size: 21px;
    font-weight: 700;
    letter-spacing: .05em;
    line-height: 30px;
    padding-bottom: 5px;
    text-align: center;
}
.subtitle {
    font-size: 14px;
    font-weight: 300;
    letter-spacing: .09em;
    line-height: 22px;
    padding-bottom: 0;
}
@media screen and (min-width: 768px) {
    .title {
        font-size: 30px;
        margin: 0 auto;
        max-width: 892px;
        padding-bottom: 20px;
    }
    .subtitle {
        font-size: 20px;
        line-height: 23px;
    }
}
</style>