<template>
  <div class="lang-select">
    <div class="lang" @click="change('en')">EN <img class="flag" src="~@/assets/en.png"></div>
    <div class="lang" @click="change('ru')">RU <img class="flag" src="~@/assets/ru.png"></div>
  </div>
</template>

<script>
export default {
    name: "Languages",
    methods: {
        change(lang) {
            this.$changeLang(lang)
        }
    }
}
</script>

<style>
.lang-select {
    position: absolute;
    top: 0px;
    right: 100px;
    z-index: 5;
    color: #DEF;
    background-color: rgba(0, 0, 0, 0.66);
    padding: 0px 5px;
}
.lang {
    font-size: 28px;
    cursor: pointer;
    margin: 5px;
    transition: .4s;
}
.lang:hover {
    background-color: rgba(100, 100, 200, 0.66);
}
.flag {
    width: 32px;
    height: auto;
}
@media (max-width: 1300px) {
	.lang {
		font-size:26px;
	}
}
@media (max-width: 1300px) {
	.lang-select {
		right: 20px
	}
}
</style>