<template>
    <div class="logo">
        <a class="logo-link">
            <img class="logo-img" alt="logo" src="~@/assets/icon-survey.svg">
            <span class="logo-text">{{ $t('logo') }}</span>
        </a>
    </div>
</template>

<script>
export default {
    name: "Logo"
}
</script>

<style>
.logo {
    padding: 1.5rem 0;
}
.logo {
  border-bottom: 1px solid #5f90fe;
}
.logo-img {
  height: 50px;
  width: 50px;
  display: block;
  float: left;
  margin: 0 1rem;
}
.logo-text {
    flex: 0 1 auto;
    font-size: 3rem;
	line-height:50px;
}
.logo-link {
	display: table;
	margin: 0 auto;
}
@media (max-width: 780px) {
	.logo-link {
		margin: inherit;
	}
}
</style>