<template>
    <div class="rain"></div>
</template>

<script>
export default {
    name: "Rain"
}
</script>

<style>
.rain {
    -webkit-animation: snow 35s linear infinite;
    animation: snow 35s linear infinite;
    -webkit-filter: blur(8px);
    filter: blur(8px);
    opacity: .7;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    transition: opacity .4s ease;
    width: 100%;
    z-index: 1;
    background-image: url('~@/assets/dollars-1.webp'),url('~@/assets/dollars-2.webp'),url('~@/assets/dollars-3.webp');
}
@keyframes snow {
    0% {
        background-position: 0 0, 0 0, 0 0;
    }
    50% {
        background-position: 500px 500px, 100px 400px, -200px 300px;
    }
    100% {
        background-position: 500px 1000px, 200px 800px, -200px, 600px;
    }
}
</style>