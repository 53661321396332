<template>
  <div class="rating-stars">
    <ul class="rating-stars-container">
        <li class="rating-star-item" v-for="(value, index) in votes" :key="index">
            <div class="rating-wrap">
                <img src="~@/assets/star.webp" class="star-img" v-for="starid in [...Array(count - index).keys()]" :key="starid">
            </div>
            <div class="rating-bar">
                <div class="rating-fill" :style="{width: width[index]}"></div>
            </div>
        </li>
    </ul>
    <div class="rating-reviews">{{ $t('rating-footer', {count: total}) }}</div>
  </div>
</template>

<script>
export default {
    name: "RatingStars",
    data() {
        return {
            votes: [193, 19, 0, 0, 2]
        }
    },
    computed: {
        count() {
            return this.votes.length
        },
        total() {
            let sum = 0;
            this.votes.forEach( value => { sum += value});
            return sum;
        },
        width() {
            let result = [];
            let sum = this.total;
            this.votes.forEach( value => { result.push(
                (value / sum * 100).toFixed(2) + '%'
            )})
            return result;
        }
    }
}
</script>

<style>
.rating-stars {
    width: 100%;
}
.rating-stars-container {
    margin: 0;
    padding: 0;
}
.rating-star-item {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    list-style: none;
}
.rating-bar {
    background: hsla(0,0%,83%,.6);
    border-radius: 10px;
    height: 8px;
    margin-left: 8px;
    max-width: 55%;
    width: 100%;
}
.rating-fill {
    background: linear-gradient(90deg,rgba(91,242,251,.6),rgba(95,144,254,.6) 91%);
    border-radius: 10px;
    height: 8px;
    width: 94%;
}
.rating-reviews {
    font-size: 10px;
    letter-spacing: .08em;
    text-align: end;
}
@media screen and (min-width: 768px) {
    .rating-bar {
        max-width: 279px;
    }
}
</style>