<template>
  <div class="comment-content">
    <img class="comment-avatar" alt="avatar" :src="comment.avatar">
    <div class="comment-data">
        <div class="comment-content-wrap">
            <h3 class="comment-name">{{ $t(comment.name) }}</h3>
            <p class="comment-text" v-if="comment.linked | false">{{ $t(comment.text) }}</p>
            <p class="comment-text" v-else>
                <span class="linked">{{ comment.linked }}</span>
                <span class="comment-text">{{ $t(comment.text) }}</span>
            </p>
        </div>
        <div class="comment-footer">
            <span class="comment-counter" v-if="comment.counter | liked">{{ (comment.counter | 0)  + (liked ? 1 : 0)}}</span>
            <span class="comment-like" @click="liked = !liked">{{ $t('like') }}</span>
            <span class="comment-time">{{ $t(comment.time) }}</span>
        </div>
        <div class="subcomments" v-if="comment.subcomments && comment.subcomments.length > 0">
            <Comment
                v-for="index in [...Array(hidden ? Math.min(comment.subcomments.length, 2) : comment.subcomments.length).keys()]"
                :key="index"
                :comment="comment.subcomments[index]"/>
            <span class="show-more" v-if="hidden && comment.subcomments.length > 2" @click="hidden = false">{{ $tc("show-more", comment.subcomments.length - 2) }}</span>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    name: "Comment",
    props: [ "comment" ],
    data() {
        return {
            hidden: true,
            liked: false
        }
    },
}
</script>

<style>
.comment-content {
    align-items: flex-start;
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    padding-top: 2rem;
}
.comment-data {
    display: flex;
    flex-flow: column;
    text-align: left;
    width: 100%;
}
.comment-avatar {
    border-radius: 1rem;
    height: auto;
    margin-right: 2rem;
    max-width: 5.3rem;
    transition: all .4s ease-in-out;
}
.comment-content-wrap {
    background-color: #474747;
    border-radius: 0.5rem;
    margin-bottom: 0.5rem;
    padding: 1rem;
}
.comment-footer {
    color: #90949c;
    align-items: center;
    display: flex;
    justify-content: flex-start;
}
.comment-name {
    font-size: 1.8rem;
    margin: 0;
    padding-bottom: 0.6rem;
    padding-top: 0.2rem;
    transition: all .4s ease-in-out;
}
.comment-text {
    display: block;
    font-size: 1.5rem;
    padding-bottom: 0.6rem;
    font-weight: 400;
    margin: 0;
    padding: 0;
}
.comment-like {
    cursor: pointer;
    font-size: 1.2rem;
    margin-right: 1.3rem;
    transition: all .4s ease-in-out;
}
.comment-counter {
    font-size: 1.2rem;
    margin-right: 1.3rem;
    transition: all .4s ease-in-out;
}
.subcomments {
    padding: 1rem 0 0;
}
.comment-time {
    font-size: 1.2rem;
    margin-right: 1.3rem;
    transition: all .4s ease-in-out;
}
.linked {
    color: #4267b2;
    font-size: 1.5rem;
    padding-bottom: 0.6rem;
    transition: all .4s ease-in-out;
}
.show-more {
    cursor: pointer;
    background: none;
    border: 1px solid #fff;
    color: #fff;
    margin: 1rem auto;
    width: -webkit-max-content;
    width: max-content;
    border-radius: 0.5rem;
    box-shadow: 0 5px 10px -3px hsla(210,9%,43%,.25);
    display: block;
    font-size: 1.5rem;
    padding: 0.7rem;
    text-align: center;
    transition: all .4s ease-in-out;
}
.show-more:hover {
    background-color: rgba(150, 150, 100, 0.7);
}
</style>