<template>
  <div class="comments-section">
    <div class="comments">
        <div class="comments-header">
            <h3 class="comments-header-sum">{{ $t('comments-sum') }}</h3>
            <div class="comments-header-sort">
                <p class="header-sort-text">{{ $t('sort-text') }}</p>
                <button class="sort-btn">{{ $t('sort-btn') }}</button>
            </div>
        </div>
        <div class="comments-main">
            <Comment v-for="(comment, index) in comments" :key="index" :comment="comment" />
        </div>
        <div class="comments-create">
            <img class="comment-avatar" alt="Unnamed avatar" src="@/assets/unnamed.webp" />
            <form class="comment-create">
                <textarea cols="30" rows="1" class="comment-textarea" :placeholder="$t('form-name')"></textarea>
                <textarea cols="30" rows="10" class="comment-textarea" :placeholder="$t('form-comment')"></textarea>
                <div class="row-create">
                    <label class="comment-label" for="create-checkbox">
                        <input type="checkbox" class="comment-checkbox" id="create-checkbox">
                        <span class="comment-checkbox-text">{{ $t('repost') }}</span>
                    </label>
                    <button class="comment-button" type="submit">{{ $t('comment') }}</button>
                </div>
            </form>
        </div>
    </div>
  </div>
</template>

<script>
import Comment from './basic/Comment.vue'
export default {
  components: { Comment },
  name: "Reviews",
  data() {
    return {
        comments: [
            {   avatar: require("@/assets/person-1.webp"),
                name:   "com-name1",
                text:   "com-text1",
                time:   "time1h"  },
            {   avatar: require("@/assets/person-14.webp"),
                name:   "com-name2",
                text:   "com-text2",
                time:   "time4h" },
            {   avatar: require("@/assets/person-2.webp"),
                name:   "com-name3",
                text:   "com-text3",
                time:   "time5h",
                linked: "@VS_john",
                subcomments: [
                    { avatar: require("@/assets/person-4.webp"),
                      name:   "com-rep1-name",
                      text:   "com-reply1",
                      time:   "time5h" }
                ] },
            {   avatar: require("@/assets/person-5.webp"),
                name: "com-name4",
                text: "com-text4",
                time: "time12h",
                counter: 26 },
            {   avatar: require("@/assets/person-6.webp"),
                name: "com-name5",
                text: "com-text5",
                time: "time12h",
                counter: 5,
                subcomments: [
                    {   avatar: require("@/assets/person-8.webp"),
                        name: "com-name6",
                        text: "com-text6",
                        time: "time4h",
                        counter: 2 }
                ] },
            {   avatar: require("@/assets/person-3.webp"),
                name: "com-name7",
                text: "com-text7",
                time: "time16h",
                counter: 216,
                subcomments: [
                    {   avatar: require("@/assets/person-9.webp"),
                        name: "com-name8",
                        text: "com-text8",
                        time: "time12h",
                        counter: 73 },
                    {   avatar: require("@/assets/person-10.webp"),
                        name: "com-name9",
                        text: "com-text9",
                        time: "time5h",
                        counter: 99 },
                    {   avatar: require("@/assets/person-6.webp"),
                        name: "com-name5",
                        text: "com-text4",
                        time: "time4h",
                        counter: 2 }
                ] },
            {   avatar: require('@/assets/person-11.webp'),
                name: "com-name10",
                text: "com-text10",
                time: "time16h",
                counter: 12 },
            {   avatar: require('@/assets/person-12.webp'),
                name: "com-name11",
                text: "com-text11",
                time: "time1d",
                counter: 456,
                subcomments: [
                    {   avatar: require('@/assets/person-13.webp'),
                        name: "com-name12",
                        text: "com-text12",
                        time: "time5h",
                        counter: 5 }
                ]}
        ]
    }
  }
}
</script>

<style>
.comments-header {
    align-items: center;
    border-bottom: 1px solid #d3d3d3;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
}
.comments-section {
    margin: 20px auto 0;
    max-width: 550px;
}
.comments {
    color: #fff;
    display: flex;
    flex-direction: column;
    font-size: 2rem;
    margin: 6rem 0;
    opacity: 1;
    padding: 0 2rem;
    transition: opacity .4s;
}
.comments-header-sum {
    margin: 0;
    font-size: 2rem;
}
.comments-header-sort {
    align-items: center;
    display: flex;
    flex-flow: row;
    justify-content: center;
}
.header-sort-text {
    font-size: 1.6rem;
    margin-right: 1rem;
}
.sort-btn {
    font-size: 1.6rem;
    padding: 0.5rem 1.2rem;
    background: none;
    border: none;
    border-radius: 0.5rem;
    cursor: text;
    outline: none;
    transition: all .4s ease-in-out;
    color: #fff;
}
.comments-create {
    align-items: flex-start;
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    order: 1;
    padding-top: 2rem;
}
.comment-avatar {
    border-radius: 1rem;
    height: auto;
    margin-right: 2rem;
    max-width: 5.3rem;
    transition: all .4s ease-in-out;
}
.comment-create {
    width: 100%;
}
.comment-textarea {
    border: 1px solid #d3d3d3;
    border-radius: 0.5rem;
    box-shadow: 0 5px 10px -3px hsla(210,9%,43%,.05);
    color: #000;
    max-height: 16rem;
    padding: 1rem;
    resize: none;
    transition: all .4s ease-in-out;
    width: inherit;
    box-sizing: border-box;
}
.row-create {
    align-items: flex-start;
    display: flex;
    font-size: 1.4rem;
    justify-content: space-between;
    padding: 1rem 0;
    flex-flow: row wrap;
}
.comment-label {
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-flow: row;
    justify-content: center;
    transition: all .4s ease-in-out;
}
.comment-checkbox {
    cursor: pointer;
    margin-right: 0.5rem;
    box-sizing: border-box;
    padding: 0;
}
.comment-button {
    background: #aaa;
    cursor: pointer;
    font-size: 2rem;
    padding: 0.5rem 2.5rem;
    outline: none;
    transition: all .4s ease-in-out;
    border: none;
    border-radius: 0.5rem;
    color: #fff;
}
@media screen and (min-width: 768px) {
    .comments-section {
        margin-top: 18px;
    }
}
</style>