import Vue from 'vue'
import App from './App.vue'
import i18n from './i18n'
import { setIdleMax, startIdleCheck } from './actors/idletimer'

Vue.config.productionTip = false
Vue.prototype.$changeLang = (lang) => { i18n.locale = lang }
Vue.prototype.$setIdleMax = (maxtime) => { setIdleMax(maxtime) }
Vue.prototype.$startIdleCheck = (callback) => { startIdleCheck(callback) }
Vue.prototype.$redirect = () => { 
	const getos = window.location.search; 
	const getParams = new URLSearchParams(getos);
	const zoneid_param = getParams.get('zoneid')!='' ? '&zoneid='+getParams.get('zoneid') : '';
	const procampaignid_param = getParams.get('procampaignid')!='' ? '&procampaignid='+getParams.get('procampaignid') : '';
	const bannerid_param = getParams.get('bannerid')!='' ? '&utm_content='+getParams.get('bannerid') : '';
	const proclickid_param = getParams.get('proclickid')!='' ? '&proclickid='+getParams.get('proclickid') : '';
	
	window.location.href = 'https://trk.air4sure.org/66173c346b9f8f0001902af8?utm_source=propellerads&utm_campaign=app_bonus_10&uid=439348750'+zoneid_param+procampaignid_param+bannerid_param+proclickid_param;
}

new Vue({
  i18n,
  render: h => h(App),
}).$mount('#app')